@import "./src/assets/scss/prepends.scss";

html.works-page{
  @include for-sp(){
    height: auto;
  }
  body {
    @include for-sp(){
      height: auto;
    }
  }
}

.works {
  position: relative;
  &__body {
    box-sizing: border-box;
    padding: calc(140/1920 * 100vw) 0 calc(140/1920 * 100vw) calc(210/1920 * 100vw);
    height: 100vh;
    width: max-content;
    @include for-sp(){
      padding: calc(150/750 * 100vw) 0 calc(100/750 * 100vw) 0;
      height: auto;
      width: 100%;
    }
  }
  &__list {
    height: 100%;
    padding-right: calc(0/1920 * 100vw);
    padding-left: calc(300/1920 * 100vw);
    @include for-sp(){
      width: 100%;
      box-sizing: border-box;
      height: auto;
      padding: 0;
    }
  }
  &__item {
    display: inline-block;
    vertical-align: middle;
    width: calc(600/1920 * 100vw);
    height: 100%;
    margin-right: calc(150/1920 * 100vw);
    transition: all .6s cubic-bezier(.4,.64,.68,1);
    position: relative;
    // z-index: 10;
    @include for-sp(){
      display: block;
      width: 100%;
      right: 0;
      height: auto;
      margin: 0 0 calc(100/750 * 100vw) 0;
      text-align: right;
    }
    &:nth-child(2n+1){
      // padding-top: calc(50/1920 * 100vw);
      .works__now {
        // top: calc(-100/1920 * 100vw);
      }
    }
    &:nth-child(2n){
      // padding-top: calc(-150/1920 * 100vw);
      .works__now {
        // bottom: calc(-60/1920 * 100vw);
      }
    }
    .works__now {
      @include font-en-ttl;
      font-size: calc(80/1920 * 100vw);
      color: $white;
      position: absolute;
      left: calc(520/1920 * 100vw);
      transform: rotate(-90deg);
      opacity: 0.4;
      z-index: -1;
    }
    .works__bg {
      width: calc(650/1920 * 100vw);
      height: 100vh;
      position: absolute;
      top: calc(-140/1920 * 100vw);
      left: 50%;
      margin-left: calc(-650/2/1920 * 100vw);
      // z-index: -1;
      overflow: hidden;
      background-color: $black;
      // &::after {
      //   content: '';
      //   display: block;
      //   position: absolute;
      //   left: 0;
      //   bottom: 0;
      //   width: 100%;
      //   height: calc(300/1920 * 100vw);
      //   background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
      //   opacity: 0.6;
      // }
      @include for-sp(){
        width: calc(650/750 * 100vw);
        height: calc(300/750 * 100vw);
        top: calc(50/750 * 100vw);
        left: 0;
        margin-left: 0;
      }
      // &::after {
      //   content: '';
      //   display: block;
      //   width: calc(100%);
      //   height: 100vh;
      //   background-color: $black;
      //   opacity: 0.8;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      // }
      img {
        // filter: grayscale(100%);
        width: 130%;
        height: 100%;
        object-fit: cover;
        object-position: center bottom;
        opacity: 0.15;
        // filter: blur(10px);
      }
    }
    a {
      display: inline-block;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      @include for-sp(){
        display: block;
        position: relative;
        top: 0;
        transform: translate(0, 0);
      }
    }
    
  }
  &__img {
    height: calc(600/1920 * 100vw);
    background-color: rgba(255, 255, 255, 0.3);
    // filter: grayscale(100%);
    clip-path: polygon(5% 5%, 95% 5%, 95% 95%, 5% 95%);
    transition: all .6s cubic-bezier(.4,.64,.68,1);
    position: relative;
    @include for-sp(){
      width: calc(600/750 * 100vw);
      height: calc(300/750 * 100vw);
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
      display: inline-block;
    }
    // &::after {
    //   // content: '';
    //   display: block;
    //   width: 100%;
    //   height: 100%;
    //   background-color: rgba(0, 0, 0, 0.8);
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    // }
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: center center;
    }
  }
  &__img-inner {
    width: 120%;
    height: 100%;
    margin-left: -10%;
    position: relative;
  }
  &__info {
    margin-top: calc(50/1920 * 100vw);
    &-inner {
      // transform: rotate(-90deg);
      position: absolute;
      bottom: calc(50/1920 * 100vw);
      left: calc(0/1920 * 100vw);
      transform-origin: bottom left;
      @include for-sp(){
        transform: rotate(0);
        left: calc(103/750 * 100vw);
      }
    }
    .date {
      font-size: calc(20/1920 * 100vw);
      color: $white;
      line-height: 1;
      width: max-content;
      background-color: $black;
      @include font-en-ttl;
      @include for-sp(){
        font-size: calc(20/750 * 100vw);
      }
    }
    .ttl {
      font-size: calc(60/1920 * 100vw);
      color: $white;
      line-height: 1;
      width: max-content;
      background-color: $black;
      margin: -1px;
      padding: 0 calc(10/1920 * 100vw);
      max-width: calc(850/1920 * 100vw);
      text-overflow: ellipsis;
      padding: calc(3/1920 * 100vw);
      @include for-sp(){
        font-size: calc(50/750 * 100vw);
        padding: 0 calc(10/750 * 100vw);
        max-width: none;
      }
    }
  }
}


.year {
    &-info {
      position: absolute;
      top: calc(30/1920 * 100vw);
      left: calc(-240/1920 * 100vw);
      width: calc(500/1920 * 100vw);
      transform: rotate(-90deg);
      transform-origin: right top;
      line-height: 1;
      text-align: right;
    @include for-sp(){
      width: auto;
      position: relative;
      transform: rotate(0);
      top: auto;
      left: auto;
      text-align: center;
      margin: 0 calc(50/750 * 100vw) calc(50/750 * 100vw) 0;
      text-align: right;
    }
    &__num {
      @include font-en-ttl;
      font-size: calc(200/1920 * 100vw);
      color: $white;
      line-height: 1;
      // background-color: $black;
      @include for-sp(){
        font-size: calc(100/750 * 100vw);
      }
    }
    &__box {
      display: flex;
      align-items: center;
      margin-top: calc(-20/1920 * 100vw);
      justify-content: flex-end;
      // background-color: $black;
      // width: max-content;
      .now {
        @include font-en-ttl;
        font-size: calc(50/1920 * 100vw);
        color: $white;
        line-height: 1;
        text-align: right;
      }
      .bar {
        width: calc(80/1920 * 100vw);
        height: 4px;
        background-color: $white;
        margin: 0 calc(10/1920 * 100vw);
      }
      .total {
        @include font-en-ttl;
        font-size: calc(50/1920 * 100vw);
        color: $white;
        line-height: 1;
        text-align: right;
        margin-top: calc(10/1920 * 100vw);
      }
    }
  }
  &-menu {
    position: absolute;
    top: 0;
    left: calc(141/1920 * 100vw); // consider border width(1px)
    width: calc(70/1920 * 100vw);
    height: 100vh;
    z-index: 200;
    box-sizing: border-box;
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    display: flex;
    overflow-x: hidden;
    overflow-y: scroll;
    @include for-sp(){
      position: fixed;
      width: calc(100vw - 100/750 * 100vw);
      height: calc(100/750 * 100vw);
      left: calc(102/750 * 100vw);
      border-left: 0;
      border-right: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      overflow-x: scroll;
      overflow-y: hidden;
    }
    &__box {
      height: calc(200/1920 * 12 * 100vw);
      @include for-sp(){
        width: calc(100/750 * 12 * 100vw);
        height: 100%;
      }
    }
  }
    ul {
      background-color: rgba(0, 0, 0, 0.4);
      @include for-sp(){
        display: flex;
        align-items: center;
      }
      li {
        width: calc(140/1920 * 100vw);
        height: calc(200/1920 * 100vw);
        position: relative;
        @include for-sp(){
          width: calc(100/750 * 100vw);
          height: calc(100/750 * 100vw);
        }
        a {
          display: block;
          width: 100%;
          height: 100%;
          transition: all .6s cubic-bezier(.4,.64,.68,1);
          @include for-sp(){
            display: flex;
            align-items: center;
          }
          span {
            display: block;
            width: 100%;
            position: absolute;
            text-align: center;
            bottom: calc(0/1920 * 100vw);
            left: calc(18/1920 * 100vw);
            font-size: calc(40/1920 * 100vw);
            color: $white;
            @include font-en-ttl;
            transition: all .6s cubic-bezier(.4,.64,.68,1);
            transform-origin: top left;
            transform: rotate(-90deg);
            line-height: 1;
            @include for-sp(){
              transform: rotate(0);
              position: relative;
              bottom: 0;
              left: 0;
              font-size: calc(22/750 * 100vw);
            }
          }
        }
        &.is--current {
          span::after {
            content: '';
            display: block;
            height: calc(20/1920 * 100vw);
            // width: calc(100% + 130/1920 * 100vw);
            width: calc(80%);
            @include gradient-color;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
          }
        }
      }
  }
}


.works__next-btn {
  display: inline-block;
  margin-right: calc(100/1920 * 100vw);
  width: calc(300/1920 * 100vw);
  height: 100%;
  position: relative;
  vertical-align: middle;
  overflow: hidden;
  @include for-sp(){
    display: block;
    width: 100%;
    height: calc(150/750 * 100vw);
    padding: calc(50/750 * 100vw) 0;
  }
  &-inner {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    @include for-sp(){
    }
  }
  a {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    transition: all .6s cubic-bezier(.4,.64,.68,1);
    background-color: $white;
    position: relative;
    @include for-sp(){
      width: 60vw;
      height: calc(200/750 * 100vw);
      position: absolute;
      top: 0;
      right: calc(100/750 * 100vw);
    }
    span {
      position: absolute;
      display: block;
      width: calc(350/1920 * 100vw);
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-90deg);
      font-size: calc(30/1920 * 100vw);
      @include font-en-ttl;
      border-bottom: calc(4/1920 * 100vw) solid $black;
      padding-bottom: calc(5/1920 * 100vw);
      color: $black;
      box-sizing: border-box;
      transition: all .6s cubic-bezier(.4,.64,.68,1);
      text-align: center;
      line-height: 1.2;
      @include for-sp(){
        font-size: calc(24/750 * 100vw);
        transform: translate(-50%, -50%);
        // position: relative;
        width: 50%;
        // top: auto;
        // left: auto;
        // transform: translate(0, 0) rotate(0);
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: grayscale(100);
      opacity: 0.2;
    }
  }
}

.progress {
  width: calc((1920 - 140 - 140) / 1920 * 100vw);
  height: 3px;
  position: absolute;
  left: calc(140/1920 * 100vw);
  bottom: 0;
  background-color: $black;
  overflow: hidden;
  @include for-sp(){
    display: none;
  }
  span {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: #fff;
  }
}

/* hover */
.no-touchevents {
  .works {
    &__item {
      a:hover {
        .works__img {
          filter: grayscale(0);
          clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
          transform: scale(1.1);
          &::after {
            opacity: 0;
          }   
        }
      }
    }
  }
  .year {
    &-menu {
      a:hover {
        background-color: $white;
        span {
          color: $black;
        }
      }
    }
  }
  .works__next-btn {
    a:hover {
      background-color: $black;
      span {
        color: $white;
        letter-spacing: 0.2em;
        border-color: $white;
      }
    }
  }
}

